<template>

	<div v-show="!loaded" class="justify-content-center text-center flex-wrap">
		<ProgressSpinner/>
		<h5>Loading Data ...</h5>
	</div>

	<div v-show="loaded" class="grid">	
		<div class="col-12 lg:col-6 xl:col-12">
			<!-- <PartnerSelect class="mr-3" /> -->
			<Calendar class="mr-3" inputId="basic" placeholder="Filter Date" v-model="selectedDateFilter" selectionMode="range" :manualInput="false" dateFormat="dd-mm-yy" :showButtonBar="true"  />
			<Dropdown inputId="gender"  v-model="selectedGender" :options="genders" optionLabel="name" optionValue="code" placeholder="Filter Gender" />
		</div>

		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0" style="height:150px;">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Number of Hours</span>
						<div class="text-900 font-medium text-xl">{{deeds?.total_hours}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-clock text-blue-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0" style="height:150px;">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">No. Transactions</span>
						<div class="text-900 font-medium text-xl">{{transactions?.total}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-yellow-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-wallet text-yellow-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0" style="height:150px;">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Number of people impacted</span>
						<div class="text-900 font-medium text-xl">{{deeds?.total_people}}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-user-plus text-orange-500 text-xl"></i>
					</div>
				</div>
			</div>
		</div>


		<div class="col-12 xl:col-4">
		<div class="card flex flex-column align-items-center">
            <h5>Top 10 Categories of Tasks Completed</h5>
            <Chart type="bar" :data="categoryData" :options="horizontalOptions"  style="width: 100%"/>
        </div>
        <div class="col-12 xl:col-12">
		<div class="card">
				<h5 class="block text-500 font-medium mb-3">Social Return on Investment</h5>
                <p>{{ sroi }}</p>
                <p>Discover your Potential</p>
                <Slider @change="onChange(discount)" :min=min :max=max v-model="discount" />
                <p> Discount on Product:
                                    {{discount}}%
                                    (actual: {{sroi_data?.discount*100}}%)
                                </p>
			</div>
	</div>
	</div>

    <div class="col-12 xl:col-4">
			<div class="card">
				<div class="flex align-items-center justify-content-between mb-4">
					<h5 class="text-500 font-medium mb-3" >Top Items</h5>
				</div>
				<!-- <span class="block text-600 font-medium mb-3">TODAY</span> -->
				<ul class="p-0 mx-0 mt-0 mb-4 list-none">
					<ScrollPanel style="width: 100%; height: 400px">
						<li v-for="item in top_items" :key="item.id"
							class="flex align-items-center py-2 border-bottom-1 surface-border">
							<div v-if="!item.image"
								class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
							</div>
							<img class="w-3rem h-3rem flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0"
								v-if="item.image"
								v-bind:src="'https://zltoexchange.s3.amazonaws.com/media/' + item.image">
							<div>
                                <p class="text-black-500"> 
                                {{ item.name }} (R{{ item.real_world_value }})
								</p>
								<span class="text-900 line-height-3">
									<span class="text-700"> 
										<span class="text-400 font-medium mb-3">{{ item.count }} Sold by {{ item.Partner }} </span>
									</span>
								</span>
							</div>
						</li>
					</ScrollPanel>
				</ul>
			</div>
		</div>
    
	<div class="col-12 xl:col-4">
		<div class="card flex flex-column align-items-center">
				<h5 class="align-self-start">Customers</h5>
				<Chart type="pie" :data="clientData" :options="genderOptions" style="width: 100%" />
			</div>
	</div>

	<!-- <div class="col-12 xl:col-4">
		<div class="card">
				<h5 class="block text-500 font-medium mb-3">Social Return on Investment</h5>
                <p>{{ sroi }}</p>
                <p>Discover your Potential</p>
                <Slider @change="onChange(discount)" :min=min :max=max v-model="discount" />
                <p> Discount on Product:
                                    {{discount}}%
                                    (actual: {{sroi_data?.discount*100}}%)
                                </p>
			</div>
	</div> -->
	<!-- <div class="col-12 xl:col-6">
		<div class="card flex flex-column align-items-center">
            <h5>Top 10 Skills</h5>
            <Chart type="bar" :data="skill_tags" :options="horizontalOptions"  style="width: 100%"/>
        </div>
	</div> -->
	</div>


</template>

<script>
import EventBus from '@/AppEventBus';

// import PartnerSelect from './PartnerDropdown';

import PartnerService from '../service/PartnerService';
import DeedService from '../service/DeedService';
import TransactionService from '../service/TransactionService';
import ClientService from '../service/ClientService';
import { store } from '../store/store.js'
import AuthEventBus from "../common/AuthEventBus";

export default {
    // components: {
	// 	PartnerSelect
    // },
	data() {
		return {
			categoryData: null,
			clientData: null,
			pieData: {
				labels: ['A', 'B', 'C'],
				datasets: [
					{
						data: [300, 50, 10],
						backgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#FFCE57"
						],
						hoverBackgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#FFCE57"
						]
					}
				]
			},
			pieOptions: null,
			genderOptions: null,
			deeds: null,
            sroi: null,
            discount: null,
            top_items: null,
            sroi_data: null,
			clients: null,
			transactions: null,
			category_labels: [],
			category_values: [],
			skill_tags_labels: [],
			skill_tags_values: [],
            min: 0,
            max: 100,
			lineData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'Revenue',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: 0.4
					},
					{
						label: 'Sales',
						data: [28, 48, 40, 19, 86, 27, 90],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e',
						tension: 0.4
					}
				]
			},
			items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
			lineOptions: null,
			horizontalOptions: null,
			skill_tags: null,
			loaded: false,
			selectedDateFilter: null,
			selectedGender: null,
			genders: [ { name: 'male', code: 'male'}, { name: 'female', code: 'female'}, { name: 'other', code: 'other'}],
			start_date: null,
			end_date: null,
		}
	},
	deedService:null,
    partnerService:null,
	transactionService:null,
	clientService:null,
	themeChangeListener: null,
	taskData: null,
	transactionData: null,
	mounted() {

        this.getSROI(localStorage.getItem('partner'))
        this.getTopItems(localStorage.getItem('partner'))
		this.getDeedData(localStorage.getItem('partner'))
		this.getTransactionData(localStorage.getItem('partner'));
		this.getClientData(localStorage.getItem('partner'));
		this.getSkillsCategories(localStorage.getItem('partner'));

		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	computed:{
		getPartner(){
			return store.partner
		},
		getSelectedDate(){
			return this.selectedDateFilter
		},
		getSelectedGender(){
			return this.selectedGender
		}
	},

    watch: {
		getPartner (newVal) {
			console.log(`New val${newVal} yay!`)
			this.getDeedData(newVal);
			this.getClientData(newVal)
			this.getTransactionData(newVal)
			this.getSkillsCategories(newVal)
            this.getSROI(newVal)
            this.getTopItems(newVal)
		},
		getSelectedDate(newVal){
			console.log(newVal)

			if(newVal != null){
				this.start_date = this.formatDate(newVal[0])
				this.end_date = this.formatDate(newVal[1])

				console.log("@@selectedDateFilter", this.start_date, this.end_date)

                this.getTopItems(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender)
				this.getDeedData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender)
				this.getClientData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender)
				this.getTransactionData(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender)
				this.getSkillsCategories(localStorage.getItem('partner'), this.start_date, this.end_date, this.selectedGender)
			}
			else{
				this.getDeedData(localStorage.getItem('partner'))
				this.getClientData(localStorage.getItem('partner'))
				this.getTransactionData(localStorage.getItem('partner'))
				this.getSkillsCategories(localStorage.getItem('partner'))
                this.getTopItems(localStorage.getItem('partner'))
			}
		},
		getSelectedGender(newVal){
			console.log("@gender", newVal)

			this.loaded = false

			if(newVal != null){
                this.getTopItems(localStorage.getItem('partner'), this.start_date, this.end_date, newVal)
				this.getDeedData(localStorage.getItem('partner'), this.start_date, this.end_date, newVal)
				this.getClientData(localStorage.getItem('partner'), this.start_date, this.end_date, newVal)
				this.getTransactionData(localStorage.getItem('partner'), this.start_date, this.end_date, newVal)
				this.getSkillsCategories(localStorage.getItem('partner'), this.start_date, this.end_date, newVal)
			}
			else{
                this.getTopItems(localStorage.getItem('partner'))
				this.getDeedData(localStorage.getItem('partner'))
				this.getClientData(localStorage.getItem('partner'))
				this.getTransactionData(localStorage.getItem('partner'))
				this.getSkillsCategories(localStorage.getItem('partner'))
			}
		}


	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.deedService = new DeedService();
		this.transactionService = new TransactionService();
		this.clientService = new ClientService();
        this.partnerService = new PartnerService();

        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = (month === 0) ? 11 : month -1;
        let prevYear = (prevMonth === 11) ? year - 1 : year;
        let nextMonth = (month === 11) ? 0 : month + 1;
        let nextYear = (nextMonth === 0) ? year + 1 : year;
        this.minDate = new Date();
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);
        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxDate.setFullYear(nextYear);

        let invalidDate = new Date();
        invalidDate.setDate(today.getDate() - 1);
        this.invalidDates = [today,invalidDate];
	},
	methods: {
		
		formatDate(date) {
			var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
			
			if (month.length < 2) {
				month = '0' + month
			}
			if (day.length < 2){
				day = '0' + day;
			} 
			return [year, month, day].join('-');
		},

		getTransactionData(id, start_date, end_date, gender){
			this.transactionService.getData(id, start_date, end_date, gender).then(data => {
				this.transactions = data.data

				this.transactionData = {
				labels: ['Earn', 'Spend'],
				datasets: [
					{
						data: [this.transactions.earn, this.transactions.spend],
						backgroundColor: [
							'#000838',
							'#FDBE1C'
						],
					}
				]
			}
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},
		getClientData(id, start_date, end_date, gender){
			this.partnerService.getItemGender(id, start_date, end_date, gender).then(data => {

				var value_data = []
				var label_data = []


				for (var x of data.data.gender_summary){

					value_data.push(x.total)
					label_data.push(x.gender)
				}
				console.log(label_data)
				console.log(value_data)

				this.clientData = {
				labels: label_data,
				datasets: [
					{
						data: value_data,
						backgroundColor: [
							'#22A7F0',
							'#000838',
							'#FDBE1C',
							"#00D115",
						],
					}
				]
			}
			this.loaded = true
			console.log('@@@@ loaded', this.loaded)
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},
		getSkillsCategories(id, start_date, end_date, gender){
			this.deedService.getSkillsCategories(id, "", start_date, end_date, gender).then(data => {


				console.log("@@@@@ data", data.data)
			let categories = []
			this.category_labels = []
			this.category_values = []

			categories = data.data?.top_categories

			categories.forEach(x => {
				this.category_labels.push(x.name)
				this.category_values.push(x.total)
			});

			let skillstags = []
			this.skill_tags_labels = []
			this.skill_tags_values = []

			skillstags = data.data?.top_skills
			skillstags.forEach(x => {
				this.skill_tags_labels.push(x.title)
				this.skill_tags_values.push(x.total)
			});

			this.categoryData = {
				labels: this.category_labels,
				datasets: [
					{
						label: 'Total',
						data: this.category_values,
						backgroundColor: [
							'#22A7F0',
							'#000838',
							'#FDBE1C',
							"#00D115",
						],
					}
				]
			}

			this.skill_tags = {
				labels: this.skill_tags_labels,
				datasets: [
					{
						label: 'Total',
						data: this.skill_tags_values,
						backgroundColor: [
							'#22A7F0',
							'#000838',
							'#FDBE1C',
							"#00D115",
						],
					}
				]
			}

			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			}
			})
	},
		getDeedData(id, start_date, end_date, gender){
			this.deedService.getDeedSummary(id, "", start_date, end_date, gender).then(data => {
			this.deeds = data.data

			this.taskData = {
				labels: ['Approved', 'Declined', 'Flagged', 'Pending'],
				datasets: [
					{
						data: [this.deeds.total_approved, this.deeds.total_declined, this.deeds.total_flagged, this.deeds.total_pending],
						backgroundColor: [
							'#22A7F0',
							'#000838',
							'#FDBE1C',
							"#00D115",
						],
					}
				]
			}



			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

        getSROI(id){
			this.partnerService.getSROI(id).then(data => {
            console.log('#####sroi', data.data)
			this.sroi_data = data.data
            this.discount = data.data.discount * 100
            this.sroi =  data.data.sroi.toFixed(2)
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

        onChange(val){
        const newSROI = (this.sroi_data.present_value * val/100) / this.sroi_data.total_product_value;
        console.log("@@@new sroi",  newSROI)
        this.sroi = newSROI.toFixed(2)
    },

        getTopItems(id, start_date, end_date, gender){
			this.partnerService.getItems(id,  start_date, end_date, gender).then(data => {
			this.top_items = data.data.slice(0, 10)
			},
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.pieOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				}
			};
            this.horizontalOptions = {
                indexAxis: 'y',
                plugins: {
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
						}
					}
				}
		};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>