import api from './api';

export default class ClientsService {
    getData(id, start_date, end_date, gender) {
        var url = `clients/${id}/summary`
        
        if(start_date){
            url = url + `?start_date=${start_date}`;
        }
        if(end_date){
            url = url + `&end_date=${end_date}`;
        }

        if(gender){
            url = url + `?&gender=${gender}`;
        }

        return api.get(`${url}`);
    }
}
