import api from './api';

export default class TransactionService {
    getData(id, start_date, end_date, gender) {

        var url = `transaction/logs/${id}/`

        console.log("@@@strat date", start_date, end_date)
        
        if(start_date){
            url = url + `?start_date=${start_date}`;
        }
        if(end_date){
            url = url + `&end_date=${end_date}`;
        }

        if(gender){
            url = url + `?&gender=${gender}`;
        }

        console.log("@@@ url", url)

        return api.get(`${url}`);
    }
}
